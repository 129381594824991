<template>
  <div class="jobs_page">

    <!-- grey intro -->
    <div class="bg-site-light pt-4 pb-4 mb-4">
      <div class="container-fluid px-5">
        <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-end py-2 text-center text-sm-left">
          <div class="flex-sm-fill">
            <h1 class="h3 font-w700 mb-0">
              Jobs
            </h1>
<!--            <h2 class="h6 font-w500 mb-0 text-muted">
              Orders sent to production at Alfredssons
            </h2>-->
          </div>
          <div class="mt-3 mt-sm-0 ml-sm-3 flex-shrink-0">
            <!--
            <a href="javascript:void(0)" class="btn btn-outline-secondary mr-1"><b-icon icon="plus-square-fill"></b-icon> New Order</a>
            <a href="javascript:void(0)" class="btn btn-secondary"><b-icon icon="gear-fill"></b-icon></a>
            -->
          </div>
        </div>
      </div>
    </div>

    <div class="px-md-3">
      <div class="container-fluid pt-3 pb-1 mb-5 bg-white shadow">
        <JobTable :filters="filters" />
      </div>
    </div>

    

  </div>
</template>



<script>
import { mapGetters } from 'vuex'

import JobTable from '@/components/JobTable/JobTable'

export default {
  components: {
    JobTable
  },

  data() {
    return {
      filters: {},
      // Flag for pending search (that the input has changed, but not enough time has passed to execute filter).
      awaitingSearch: false,

      // Filter: Status
      table_status_filters: [
        {id: null, text: 'Select to filter...' },
        {id: 'incoming', text: 'Incoming'},
        {id: 'in-production', text: 'In production'},
        {id: 'cancelled', text: 'Cancelled'},
        {id: 'failed', text: 'Failed'},
        {id: 'completed', text: 'Completed'},
        {id: 'warnings', text: 'Warnings'}
      ],
    }
  },

  created() {
    // Set Sidebar Active Part
    this.$store.commit("SET_SITE_SETTINGS", {'sidebar_current': 'jobs'});

    let query = Object.assign({}, this.$route.query);
    if ( query?.status ) {
      this.filters.status = query.status;
      delete query.status;
      this.$router.replace({ query });
    }
  },

  computed: {
    ...mapGetters([
      'timestampAsDate',
      'countryByCode',
    ]),
    ...mapGetters('user', [
      'userHasRole',
    ]),
  },

  methods: {
  }
}
</script>

<style lang="scss">
</style>